.mod-invoice-action-log{
  padding: 20px;
}

.mod-invoice-action-log .table-wrapper{
  height: 80vh;
  overflow: auto;
}

.mod-invoice-action-log table{
  width: 100%;
  /* border-collapse: collapse; */
  border-collapse: separate;
  border-spacing: 0px;
}

.mod-invoice-action-log table thead{
  white-space: nowrap;
  text-align: left;
}

.mod-invoice-action-log table thead th{
  padding: 10px;
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid;
}

.mod-invoice-action-log table td{
  padding: 20px 10px;
}

.mod-invoice-action-log table tbody tr:nth-child(odd){
  background-color: #F2F2F2;
}

.mod-invoice-action-log tbody tr.active,
.mod-invoice-action-log tbody tr:hover{
  background-color: rgba(242,201,76,0.3); 
}

.mod-invoice-action-log table td:not(.note){
  white-space: nowrap;
}

.mod-invoice-action-log table td.note{
  width: 500px;
}

