.main-invoice-list{
  padding: 10px;
}

.main-invoice-list .filter-row{
  display: flex;
  flex-wrap: wrap;
}

.main-invoice-list .filter-item{
  display: flex; 
  align-items: center; 
  flex: 1;
}

.main-invoice-list .filter-input-wrapper,
.main-invoice-list .filter-label-wrapper{
  margin: 10px;
}

.main-invoice-list .filter-input-wrapper{
  flex: 1;
}

.main-invoice-list .filter-row .ui.button{
  margin: 10px;
  background-color: #56CCF2;
  color: white;
}

.main-invoice-list .filter-row .DayPickerInput input{
  height: 38px;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding-left: 8px;
}

.main-invoice-list .table-wrapper{
  overflow: auto;
  height: calc(75vh - 60px);
}

.main-invoice-list .invoice-table{
  width: 100%;
  /* border-collapse: collapse; */
  border-collapse: separate;
  border-spacing: 0px;
}

.main-invoice-list .invoice-table thead{
  white-space: nowrap;
  text-align: left;
}

.main-invoice-list .invoice-table thead th.price{
  text-align: right;
}

.main-invoice-list .invoice-table thead th{
  padding: 10px;
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid;
  cursor: pointer;
}

.main-invoice-list .invoice-table td{
  padding: 20px 10px;
}

.main-invoice-list .purchase,
.main-invoice-list .receive,
.main-invoice-list .bill,
.main-invoice-list .outsource,
.main-invoice-list .transport,
.main-invoice-list .payment,
.main-invoice-list .other,
.main-invoice-list .related-documents,
.main-invoice-list .invoice-status{
  white-space: nowrap;
}

.main-invoice-list .price{
  text-align: right;
}

.main-invoice-list tbody tr:nth-child(odd){
  background-color: #F2F2F2;
}

.main-invoice-list .invoice-table tr.active,
.main-invoice-list tbody tr:hover{
  background-color: rgba(242,201,76,0.3); 
}

.main-invoice-list .invoice-table .sale-name, 
.main-invoice-list .invoice-table .shipper {
  min-width: 140px;
}

.main-invoice-list .invoice-table .delivery-address,
.main-invoice-list .invoice-table .customer-name{
  min-width: 170px;
}

.main-invoice-list .invoice-table .serial{
  color: #2D9CDB;
}

.main-invoice-list .invoice-table thead th.break,
.main-invoice-list .invoice-table .break{
  border: 0;
  width: 10px;
  background-color: white;
}

.main-invoice-list .DayPickerInput-Overlay {
  z-index: 50;
}

.main-invoice-list .filter-row .update-button.ui.button{
  box-shadow:0 0 0 0.5px #d4d4d5 inset !important;
  background-color: #151515;
}
