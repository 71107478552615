.container {
    /* display: grid; 
    grid-template-rows: 10% 90%;
    width: 100%; */
    /* height: 90%; */
}
    
.menu {
    padding: 4px 10px;
    min-height: 50px;
    /* height: 50px; */
    /* grid-row: 1; */
    display: flex;
    background-color: #00529C; 
    align-items: center; 
    justify-content: space-between;
    /* width: 100%;
    height: 100%; */
}

.menuitem {
    padding: 0px 20px;
    color: white;
    text-align: center
}

.content {
    grid-row: 2;
}