.mod-update-invoice > div{
  align-items: center;
  padding: 10px;
}

.mod-update-invoice h2{
  text-align: center; 
  padding: 10px;
}

.mod-update-invoice .row{
  display: flex; 
  margin: 15px;
}

.mod-update-invoice .last.row{
  justify-content: flex-end;
}

.mod-update-invoice .row .key-field{
  flex: 1;
  text-align: right; 
  margin-right: 50px;
}

.mod-update-invoice .row .input-field{
  flex: 3;
}