.ui.button.ImageButton{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    width: 250px;
    border-radius: 20px;
    border: 1px solid #ebebeb;
    border-bottom: 5px solid #ebebeb;
}

.ui.button.ImageButton:hover{
    background-color:#ebebeb;
    
}