.main-forget-password .ui.input input,
.main-forget-password .ui.input input:focus{
  background-color: rgba(40, 134, 189, 0.1);
  border: 0;
  color: #00529C;
}

.main-forget-password .ui.input.white-input input{
  background-color: white;
  border: 0;
  color: #00529C;
}

.main-forget-password b{
  white-space: nowrap;
}