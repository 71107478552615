.mod-edit-sale > div{
  align-items: center;
  padding: 10px;
}

.mod-edit-sale h2{
  text-align: center; 
  padding: 10px;
}

.mod-edit-sale .row{
  display: flex; 
  margin: 15px;
}

.mod-edit-sale .last.row{
  justify-content: flex-end;
}

.mod-edit-sale .row .key-field{
  flex: 1;
  text-align: right; 
  margin-right: 50px;
}

.mod-edit-sale .row .key-field:not(.reason){
  align-self: center;
}

.mod-edit-sale .row .input-field{
  flex: 3;
}

.mod-edit-sale .row .input-field .ui.input,
.mod-edit-sale .row .input-field textarea{
  width: 100%;
}