
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 8.484005563282336% 14.325452016689846% 6.258692628650904% 0.13908205841446453% 0.8344923504867872% 2.7816411682892905% 1.1126564673157162% 8.205841446453407% 0.6954102920723226% 0.6954102920723226% 6.675938803894297% 0.6954102920723226% 0.6954102920723226% 12.934631432545201% 2.364394993045897% 11.543810848400556% 2.2253129346314324% 3.0598052851182196% 1.2517385257301807% 15.020862308762169%;
    grid-template-columns: 35.340109460516025% 5.863956215793589% 0.547302580140735% 0.7036747458952306% 0.1563721657544957% 2.501954652071931% 0.3127443315089914% 1.016419077404222% 0.39093041438623927% 0.39093041438623927% 4.769351055512119% 0.9382329945269742% 0.23455824863174354% 0.1563721657544957% 0.39093041438623927% 0.9382329945269742% 0.9382329945269742% 1.6419077404222049% 1.016419077404222% 6.333072713057076% 35.41829554339328%;
}

.Item1 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 21;
    width: 100%;
    height: 100%;
}
.Item4 {
    grid-row-start: 3;
    grid-row-end: 8;
    grid-column-start: 3;
    grid-column-end: 21;
    width: 100%;
    height: 100%;
}
.Item5 {
    grid-row-start: 8;
    grid-row-end: 12;
    grid-column-start: 3;
    grid-column-end: 21;
    width: 100%;
    height: 100%;
}
.Item8 {
    grid-row-start: 14;
    grid-row-end: 17;
    grid-column-start: 3;
    grid-column-end: 21;
    width: 100%;
    height: 100%;
}
.Home {
    grid-row-start: 16;
    grid-row-end: 16;
    grid-column-start: 3;
    grid-column-end: 21;
    width: 100%;
    height: 100%;
}
