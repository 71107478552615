.invoice-summary tbody tr:nth-child(even){

}

.invoice-summary tbody tr:nth-child(odd){
  background-color: #F2F2F2;
}

.invoice-summary tbody tr:hover{
  background-color: rgba(242,201,76,0.3); 
}