.main-employee.ag-theme-alpine{
  height: calc(78vh - 50px);
}

.ag-theme-alpine .upload-fail{
  background-color:rgba(235,87,87, 0.5)
}

.main-employee-list{
  padding: 10px;
}

.main-employee-list .filter-row{
  display: flex;
  flex-wrap: wrap;
}

.main-employee-list .filter-item{
  display: flex; 
  align-items: center; 
}

.main-employee-list .filter-input-wrapper,
.main-employee-list .filter-label-wrapper{
  margin: 10px;
}

.main-employee-list .filter-input-wrapper{
  flex: 1;
}

.main-employee-list .filter-row .ui.button{
  margin: 10px;
  background-color :#21ba45;
  color: white;
}
