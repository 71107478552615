
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 8.484005563282336% 14.325452016689846% 6.258692628650904% 0.13908205841446453% 0.8344923504867872% 2.7816411682892905% 1.1126564673157162% 4.867872044506258% 1.8080667593880388% 0.8344923504867872% 1.8080667593880388% 0.13908205841446453% 1.9471488178025034% 3.8942976356050067% 1.5299026425591098% 0.6954102920723226% 1.9471488178025034% 0.5563282336578581% 1.8080667593880388% 6.258692628650904% 2.2253129346314324% 3.0598052851182196% 1.2517385257301807% 2.642559109874826% 2.364394993045897% 2.2253129346314324% 1.9471488178025034% 2.5034770514603615% 2.2253129346314324% 17.52433936022253%;
    grid-template-columns: 35.340109460516025% 1.016419077404222% 0.9382329945269742% 0.23455824863174354% 1.4855355746677092% 0.07818608287724785% 1.1727912431587177% 0.07818608287724785% 0.8600469116497264% 0.547302580140735% 0.7036747458952306% 0.1563721657544957% 2.3455824863174355% 1.3291634089132134% 0.39093041438623927% 0.07818608287724785% 0.1563721657544957% 5.08209538702111% 1.09460516028147% 0.07818608287724785% 0.1563721657544957% 0.23455824863174354% 3.674745895230649% 1.016419077404222% 5.3166536356528535% 1.016419077404222% 35.41829554339328%;
}

.Item1 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 26;
    width: 100%;
    height: 100%;
}
.Item4 {
    grid-row-start: 6;
    grid-row-end: 7;
    grid-column-start: 2;
    grid-column-end: 26;
    width: 100%;
    height: 100%;
}
.Username {
    grid-row-start: 13;
    grid-row-end: 17;
    grid-column-start: 4;
    grid-column-end: 26;
    width: 100%;
    height: 100%;
}
.Password {
    grid-row-start: 18;
    grid-row-end: 20;
    grid-column-start: 4;
    grid-column-end: 26;
    width: 100%;
    height: 100%;
}
.Login {
    grid-row-start: 23;
    grid-row-end: 26;
    grid-column-start: 11;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.Register {
    grid-row-start: 27;
    grid-row-end: 30;
    grid-column-start: 11;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.Item17 {
    grid-row-start: 30;
    grid-row-end: 30;
    grid-column-start: 11;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.Item20 {
    grid-row-start: 13;
    grid-row-end: 17;
    grid-column-start: 2;
    grid-column-end: 4;
    width: 100%;
    height: 100%;
}
.Item21 {
    grid-row-start: 18;
    grid-row-end: 20;
    grid-column-start: 2;
    grid-column-end: 4;
    width: 100%;
    height: 100%;
}