
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 1.9471488178025034% 2.7816411682892905% 0.27816411682892905% 2.5034770514603615% 0.6954102920723226% 2.2253129346314324% 9.457579972183588% 1.1126564673157162% 1.5299026425591098% 0.27816411682892905% 0.13908205841446453% 0.13908205841446453% 0.9735744089012517% 1.9471488178025034% 0.8344923504867872% 0.27816411682892905% 1.5299026425591098% 0.27816411682892905% 0.13908205841446453% 0.13908205841446453% 0.9735744089012517% 7.510431154381084% 1.6689847009735743% 3.198887343532684% 1.6689847009735743% 8.6230876216968% 3.477051460361613% 3.6161335187760777% 1.5299026425591098% 0.13908205841446453% 1.5299026425591098% 0.27816411682892905% 0.13908205841446453% 3.755215577190542% 0.27816411682892905% 0.5563282336578581% 1.2517385257301807% 0.27816411682892905% 0.13908205841446453% 1.5299026425591098% 0.13908205841446453% 1.9471488178025034% 0.4172461752433936% 0.4172461752433936% 1.3908205841446453% 0.27816411682892905% 0.13908205841446453% 1.3908205841446453% 0.13908205841446453% 6.119610570236439% 5.1460361613351875% 1.9471488178025034% 2.2253129346314324% 2.364394993045897% 4.589707927677329%;
    grid-template-columns: 2.4237685691946833% 8.522283033620015% 0.07818608287724785% 8.131352619233777% 1.2509773260359656% 1.1727912431587177% 1.7200938232994527% 5.473025801407349% 0.547302580140735% 0.1563721657544957% 0.07818608287724785% 0.1563721657544957% 0.07818608287724785% 0.23455824863174354% 0.7818608287724785% 0.1563721657544957% 0.4691164972634871% 1.2509773260359656% 0.07818608287724785% 3.674745895230649% 0.547302580140735% 1.016419077404222% 1.016419077404222% 1.2509773260359656% 2.8146989835809224% 0.39093041438623927% 0.547302580140735% 6.802189210320563% 0.4691164972634871% 2.6583268178264268% 0.4691164972634871% 1.016419077404222% 1.1727912431587177% 0.3127443315089914% 1.6419077404222049% 0.4691164972634871% 0.6254886630179828% 7.740422204847537% 0.4691164972634871% 0.4691164972634871% 0.23455824863174354% 0.1563721657544957% 3.674745895230649% 8.287724784988272% 0.1563721657544957% 0.4691164972634871% 0.39093041438623927% 0.07818608287724785% 1.7982799061767005% 4.534792806880375% 0.7036747458952306% 1.3291634089132134% 6.333072713057076% 3.5183737294761532%;
}

.Item1 {
    grid-row-start: 2;
    grid-row-end: 8;
    grid-column-start: 2;
    grid-column-end: 3;
    width: 100%;
    height: 100%;
}
.Item2 {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 43;
    grid-column-end: 53;
    width: 100%;
    height: 100%;
}
.Username {
    grid-row-start: 8;
    grid-row-end: 14;
    grid-column-start: 18;
    grid-column-end: 47;
    width: 100%;
    height: 100%;
}
.Item7 {
    grid-row-start: 8;
    grid-row-end: 14;
    grid-column-start: 8;
    grid-column-end: 15;
    width: 100%;
    height: 100%;
    text-align: right;
}
.EmployeeNumber {
    grid-row-start: 15;
    grid-row-end: 22;
    grid-column-start: 18;
    grid-column-end: 47;
    width: 100%;
    height: 100%;
}
.Item11 {
    grid-row-start: 15;
    grid-row-end: 22;
    grid-column-start: 8;
    grid-column-end: 15;
    width: 100%;
    height: 100%;
    text-align: right;
}
.Item13 {
    grid-row-start: 23;
    grid-row-end: 26;
    grid-column-start: 25;
    grid-column-end: 38;
    width: 100%;
    height: 100%;
}
.Item15 {
    grid-row-start: 27;
    grid-row-end: 51;
    grid-column-start: 4;
    grid-column-end: 53;
    width: 100%;
    height: 100%;
}
.Item16 {
    grid-row-start: 28;
    grid-row-end: 29;
    grid-column-start: 25;
    grid-column-end: 38;
    width: 100%;
    height: 100%;
    text-align: center;
}
.Item17 {
    grid-row-start: 30;
    grid-row-end: 34;
    grid-column-start: 16;
    grid-column-end: 46;
    width: 100%;
    height: 100%;

}
.Password {
    grid-row-start: 35;
    grid-row-end: 41;
    grid-column-start: 16;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.Item25 {
    grid-row-start: 35;
    grid-row-end: 41;
    grid-column-start: 7;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
    text-align: right;
}
.Item26 {
    grid-row-start: 35;
    grid-row-end: 41;
    grid-column-start: 49;
    grid-column-end: 50;
    width: 100%;
    height: 100%;
}
.ConfirmPassword {
    grid-row-start: 43;
    grid-row-end: 49;
    grid-column-start: 16;
    grid-column-end: 45;
    width: 100%;
    height: 100%;
}
.Item29 {
    grid-row-start: 43;
    grid-row-end: 49;
    grid-column-start: 7;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
    text-align: right;
}
.Item30 {
    grid-row-start: 43;
    grid-row-end: 49;
    grid-column-start: 49;
    grid-column-end: 50;
    width: 100%;
    height: 100%;
}
.SetPassword {
    grid-row-start: 52;
    grid-row-end: 55;
    grid-column-start: 25;
    grid-column-end: 38;
    width: 100%;
    height: 100%;
}
