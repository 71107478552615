.mod-update-doc-note > div{
  align-items: center;
  padding: 10px;
}

.mod-update-doc-note h3{
  display: flex;
  align-items: center;
}

.mod-update-doc-note .row{
  display: flex; 
  margin: 15px;
  align-items: center;
}

.mod-update-doc-note .last.row{
  justify-content: flex-end;
}

.mod-update-doc-note .row .key-field{
  flex: 1;
  text-align: right; 
  margin-right: 50px;
}

.mod-update-doc-note .row .input-field{
  flex: 3;
}

.mod-update-doc-note .table-wrapper{
  /* height: 60vh; */
  overflow: auto;
}

.mod-update-doc-note table{
  width: 100%;
  /* border-collapse: collapse; */
  border-collapse: separate;
  border-spacing: 0px;
  /* height: 60vh; */
}

.mod-update-doc-note table thead{
  white-space: nowrap;
  text-align: left;
}

.mod-update-doc-note table thead th{
  padding: 10px;
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid;
}

.mod-update-doc-note table td{
  padding: 20px 10px;
}

.mod-update-doc-note table tbody tr:nth-child(odd){
  background-color: #F2F2F2;
}

.mod-update-doc-note tbody tr.active,
.mod-update-doc-note tbody tr:hover{
  background-color: rgba(242,201,76,0.3); 
}

.mod-update-doc-note table td:not(.note){
  white-space: nowrap;
}
