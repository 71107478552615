.mod-billing-note{
  padding: 20px;
}

.mod-billing-note .row{
  display: flex;
  margin: 15px;
  flex: 1;
}

.mod-billing-note .row .item{
  flex: 1;
}

.mod-billing-note .text-area{
  width: 100%;
  resize: none;
}

.mod-billing-note .row .item-end{
  flex: 1;
  text-align-last: end;
}

.mod-billing-note .table-wraper{
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;
  height: 60vh;
}

.mod-billing-note table{
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
}

.mod-billing-note table thead{
  white-space: nowrap;
  text-align: left;
}

.mod-billing-note table thead th{
  padding: 10px;
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid;
}

.mod-billing-note table td{
  padding: 20px 10px;
}

.mod-billing-note table tbody tr:nth-child(odd){
  background-color: #f2f2f2;
}

.mod-billing-note tbody tr.active,
.mod-billing-note tbody tr:hover{
  background-color: rgba(242, 201, 76, 0.3);
}

.mod-billing-note table td:not(.note){
  white-space: nowrap;
}

.mod-billing-note table td.note{
  width: 500px;
}
