.mod-invoice-document-image-list table{
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
}

.mod-invoice-document-image-list .table-wrapper{
  height: 550px;
  overflow: auto;
  width: 100%;
  padding-right: 0;
}

.mod-invoice-document-image-list table th,
.mod-invoice-document-image-list table td{
  padding: 20px;
}

.mod-invoice-document-image-list table thead th{ 
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: #F2F2F2;
}

.mod-invoice-document-image-list table tbody td{
  border: 1px solid #F2F2F2;
}

.mod-invoice-document-image-list table tbody tr:not(.no-image) td:hover,
.mod-invoice-document-image-list table tbody tr.active:not(.no-image){
  background-color: rgba(216, 240, 255, 0.5);
}

.mod-invoice-document-image-list .serial,
.mod-invoice-document-image-list h1{
  color: #00529C;
}

.mod-invoice-document-image-list .zoom{
    position: absolute;
    align-self: flex-start;
    display: flex;
    width: 90%;
    padding-top: 10px;
    flex-direction: column;
}

.zoom .icon-in{
  margin: 5px;
  font-size: 20px !important;
  color: #f0f8ff38;
}

.zoom .icon-in:hover{
  color: #FFFFFF;
}