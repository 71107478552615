.mod-cancel-invoice > div{
    align-items: center;
    padding: 10px;
  }

.mod-cancel-invoice h2{
  text-align: center;
  color: #333333 ;
}

.mod-cancel-invoice .stack{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.mod-cancel-invoice .row{
    display: flex;
    flex-direction: row;
    margin: 15px;
}

.mod-cancel-invoice .item{
    color: #828282;
    font-size: 18px;
    align-self: center;
    font-weight: bold;
    padding-right: 10px;
}

.mod-cancel-invoice .item-invoice{
    font-size: 15px;
    color: #828282;
    max-height: 80px;
    width: auto;
    align-self: center;
    padding-right: 10px;
    overflow-y: auto;
}

.mod-cancel-invoice .invoice{
    padding: 5px; 
    font-size: 16px;
}